<template>
  <div class="starProduct"
  :style="{background:data.type=='trme'?'#BDDBC2':''}">
    <img 
      :src="data.imgInfo[data.type].bg" 
      alt=""
      :style="{top:data.type=='trme'?'-0.9rem':0}"
      class="bg">
    <div class="content">
      <div class="title">
        <img  
        :src="data.imgInfo[data.type].title"  
        alt="">
      </div>
      <div class="banner">
        <img  
        :src="data.imgInfo[data.type].banner"  
        alt="">
      </div>
      <div class="tabList">
        <div 
          class="tabItem" 
          :class="data.type=='trme'?'trmeSel':''"
          @click="changeTab('trme')">
          <img 
          v-if="data.type=='trme'"
          class="tabBg_trme"
          :src="data.url+'starProduct/trmeSel.png'" >
          <span>
            ageLOC TRME<br/>体型管理计划
          </span>
        </div>
        <div 
          class="tabItem" 
          :class="data.type=='loc'?'trmeSel':''"
          @click="changeTab('loc')">
          <img 
          v-if="data.type=='loc'"
          class="tabBg_loc"
          :src="data.url+'starProduct/locSel.png'" >
          <span>
            ageLOC<br/>WellSpa iO
          </span>
        </div>
      </div>
      <div class="menuList">
        <div class="menuItem" @click="goProduct">
          <div class="wrap" :class="data.type=='loc'?'locWrap':''"></div>
          <img  
          :src="data.url+'starProduct/leftArrow.png'" 
          alt="">
          <div class="productText">
            产品相关信息
            <div class="line"></div>
          </div>
          <img  
          :src="data.url+'starProduct/rightArrow.png'" 
          alt="">
        </div>
        <div class="menuItem" @click="goMaterial">
          <div class="wrap" :class="data.type=='loc'?'locWrap':''"></div>
          <img  
          :src="data.url+'starProduct/leftArrow.png'" 
          alt="">
          <div class="productText">
            沟通物料
            <div class="line"></div>
          </div>
          <img  
          :src="data.url+'starProduct/rightArrow.png'" 
          alt="">
        </div>
      </div>
      <div class="desc">
        <img :src="data.imgInfo[data.type].desc">
      </div>
      <div class="empty" style="height: 1rem;"></div>
    </div>
    <div class="backBtn" @click="backIndex">
      <img v-if="data.type=='trme'" :src="data.url+'trme/backBtn.png'" alt="">
      <img v-if="data.type=='loc'" :src="data.url+'wellspa/backBtn.png'" alt="">
    </div>
  </div>
</template>
<script setup>
  import { useRouter } from "vue-router";
  import {reactive,onMounted} from 'vue';
  import {baiduTotal} from "../js/common.js";
  let data=reactive({
    type:'trme',//trme,loc
    imgInfo:{
      trme:{
        bg:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/trme/bg.png',
        title:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/starProduct/trmTitle.png',
        banner:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/starProduct/trmeImg.png',
        tabBg:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/starProduct/trmeSel.png',
        desc:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/starProduct/trmeDes.png?2'
      },
      loc:{
        bg:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/wellspa/bg.png',
        title:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/starProduct/locTitle.png',
        banner:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/starProduct/locImg.png',
        tabBg:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/starProduct/locSel.png',
        desc:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/starProduct/logDes.png?555'
      }
    },
    url:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/',//图片地址
  })
  const router = useRouter();
  //返回首页
  let backIndex=()=>{
    router.replace('/index');
  }
  //切换导航
  const changeTab=(tab)=>{
    data.type=tab;
  }
  //产品首页
  let goProduct=()=>{
    sessionStorage.setItem('tab',data.type);
    if(data.type=='trme'){
      router.push({
        path: `/trmeDetial`,
      });
    }else{
      router.push({
        path:"/wellspaDetail",
      });
    }
  }
  //trme物料
  let goMaterial=()=>{
    sessionStorage.setItem('tab',data.type);
    if(data.type=='trme'){
      router.push({
        path: `/trmeMaterial`,
      });
    }else{
      router.push({
        path:"/wellspaMaterial",
      });
    }
  }
  onMounted(()=>{
    let tab =sessionStorage.getItem('tab');
    if(tab){
      data.type=tab;
    }
    baiduTotal();
  })
</script>
<style lang="scss" scoped>
.starProduct{
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  background: #E4E5E7;
  overflow-y: auto;
  .bg{
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .content{
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    padding: 0.26rem 0 0;
    box-sizing: border-box;
    .title{
      width:3.25rem;
      margin: 0.06rem auto 0;
      font-size: 0;
      img{
        width: 100%;
      }
    }
    .banner{
      width: 100%;
      font-size: 0;
      margin-top: 0.28rem;
      img{
        width: 100%;
      }
    }
    .tabList{ 
      width: 3.5rem;
      height: 0.48rem;
      border-radius: 0.2rem;
      background: #fff;
      margin-top: 0.12rem auto;
      display: flex;
      align-items: center;
      margin:0 auto;
      .tabItem{
        flex: 1;
        width: 0;
        height: 0.48rem;
        text-align: center;
        font-size: 0.14rem;
        font-weight: bold;
        color: #1B1B1B;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        .tabBg_trme{
          position: absolute;
          left: 0;
          bottom: 0;
          height: 0.52rem;
          z-index: 1;
        }
        .tabBg_loc{
          position: absolute;
          right: 0;
          bottom: 0;
          height: 0.52rem;
          z-index: 1;
        }
        span{
          position: relative;
          z-index: 2;
        }
      }
      .trmeSel{
        color: #fff;
      }
    }
    .menuList{
      width: 3.5rem;
      height: 0.34rem;
      margin-top: 0.12rem auto;
      display: flex;
      align-items: center;
      margin:0.12rem auto 0.16rem ;
      .menuItem{
        flex: 1;
        width: 0;
        height: 0.34rem;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .wrap{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(90deg,rgba(0,169,155,0),rgba(6,207,190,0.8),rgba(0,169,155,0));
          filter: blur(10px);
        }
        .locWrap{
          background: linear-gradient(90deg,rgba(126,141,161,0),rgba(178,186,197,0.8 ),rgba(126,141,161,0)); 
        }
        .productText{
          font-size: 0.14rem;
          color: #fff;
          margin: 0 8px;
          font-weight: bold;
          position: relative;
          .line{
            position: absolute;
            left: 0;
            bottom: -2px;
            width: 100%;
            height: 2px;
            background: linear-gradient(90deg,rgba(255,255,255,0.3),rgba(255,255,255,1));
          }
        }
        img{
          height: 0.14rem;
        }
      }
    }
    .desc{
      width: 100%;
      img{
        width: 100%;
      }
    }
  }

  .backBtn{
    position: fixed;
    right: 0;
    bottom: 0;
    width: 1.17rem;
    height: 1.28rem;
    font-size: 0;
    z-index: 10;
    img{
      width: 100%;
    }
  }
}
</style>